import type { SDKRequestInterface } from '@cloudbase/adapter-interface'
import { Bot } from './bot'
import * as models from './models'
import * as types from './type'
import { readableStream2JsonObject } from './utils'

const { MODELS } = models

class AI {
  public aiBaseUrl: string
  public aiBotBaseUrl: string
  public bot: Bot

  constructor(private req: SDKRequestInterface, public baseUrl: string) {
    this.aiBaseUrl = `${baseUrl}/ai`
    this.aiBotBaseUrl = `${baseUrl}/aibot`
    this.bot = new Bot(this.botRequest, this.aiBotBaseUrl)
  }

  createModel<T extends keyof typeof MODELS>(model: T) {
    const SimpleModelConstructor = MODELS[model]
    const simpleModel: types.SimpleChatModel = new SimpleModelConstructor(this.modelRequest, this.aiBaseUrl)
    const reactModel = new models.ReactModel(simpleModel)
    return reactModel
  }

  registerModel(name: string, model: types.ChatModelConstructor) {
    if (MODELS[name] != null) {
      console.warn(`AI model ${name} already exists!`)
      return
    }
    MODELS[name] = model
  }

  modelRequest: types.ModelReq = async ({ url, data, headers, stream }) => {
    const fetchHeaders = {
      'Content-Type': 'application/json',
    }
    // 带 Accept: text/event-stream  请求头显式指定是 SSE 绕过 60s 的限制
    stream && Object.assign(fetchHeaders, { Accept: 'text/event-stream' })

    const { data: responseData, header } = (await this.req.fetch({
      method: 'post',
      headers: { ...fetchHeaders, ...headers },
      body: JSON.stringify(data),
      url,
      stream,
    })) as { data: Promise<unknown> | ReadableStream<Uint8Array>; header?: Headers }

    return handleResponseData(responseData, header) as any
  }

  botRequest: types.BotReq = async ({ method, url, data = {}, headers, stream }) => {
    if (method === 'get') {
      return handleResponseData((await this.req.fetch({ url: `${url}?${objectToParam(data)}`, method, headers, stream })).data,)
    }

    const fetchHeaders = {
      'Content-Type': 'application/json',
    }
    // 带 Accept: text/event-stream  请求头显式指定是 SSE 绕过 60s 的限制
    stream && Object.assign(fetchHeaders, { Accept: 'text/event-stream' })

    const { data: responseData, header } = (await this.req.fetch({
      url,
      body: JSON.stringify(data),
      headers: {
        ...fetchHeaders,
        ...headers,
      },
      stream,
      method,
    })) as { data: Promise<unknown> | ReadableStream<Uint8Array>; header?: Headers }

    return handleResponseData(responseData, header) as any

    function objectToParam(obj: Object) {
      return Object.entries(obj)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')
    }
  }

  registerFunctionTool(functionTool: types.FunctionTool) {
    if (models.toolMap.has(functionTool.name)) {
      console.warn(`AI function tool ${functionTool.name} already exists and will be overwritten!`)
    }
    models.toolMap.set(functionTool.name, functionTool.fn)
  }
}

export { AI }

const GO_TO_AI_TEXT = '请检查调用方式，或前往云开发 AI+ 首页查看文档：https://tcb.cloud.tencent.com/dev#/ai'

async function handleResponseData(responseData: Promise<unknown> | ReadableStream<Uint8Array>, header?: Headers) {
  if ('then' in responseData) {
    // 非流式请求，直接返回 json 数据
    const json = (await responseData) as Record<string, unknown>
    if ('code' in json && json.code !== 'NORMAL') {
      throw new Error(`AI+ 请求出错，错误码：${json.code}，错误信息：${json.message}\n${GO_TO_AI_TEXT}\n${JSON.stringify(
        json,
        null,
        2,
      )}`,)
    }

    return responseData
  }
  // 流式请求，如果接口出错，会变成传 json 格式的响应，通过 header 判断格式
  if (header?.get?.('content-type')?.includes('application/json')) {
    const json = await readableStream2JsonObject(responseData)
    if ('code' in json && json.code !== 'NORMAL') {
      throw new Error(`AI+ 请求出错，错误码：${json.code}，错误信息：${json.message}\n${GO_TO_AI_TEXT}\n${JSON.stringify(
        json,
        null,
        2,
      )}`,)
    }
  }
  return responseData
}
