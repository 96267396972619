import { type ParsedEvent } from '../eventsource_parser'
import { BotReq } from '../type'
import {
  IBotPreview,
  IBotSendMessage,
  ICreateBot,
  IDeleteBot,
  IGenerateBot,
  IGenerateImage,
  IGetBot,
  IGetBotChatRecords,
  IGetBotFeedback,
  IGetBotList,
  IGetBotRecommendQuestions,
  ISendBotFeedback,
  IUpdateBot,
} from './types'
import {
  createAsyncIterable,
  toPolyfillReadable,
  createEventSourceParserTransformStream,
  TextDecoderStream,
  TransformStream,
} from '../utils'

export class Bot {
  req: BotReq

  constructor(req: BotReq, public baseUrl: string) {
    const token = arguments[2]
    if (typeof token === 'string') {
      this.req = ({ headers = {}, ...rest }) => req({ ...rest, headers: { ...headers, Authorization: `Bearer ${token}` } })
    } else {
      this.req = req
    }
  }

  list(props: IGetBotList) {
    return this.req({
      method: 'get',
      url: this.join('bots'),
      data: props,
    })
  }

  create({ botInfo }: ICreateBot) {
    return this.req({
      method: 'post',
      url: this.join('bots'),
      data: botInfo,
    })
  }

  get({ botId }: IGetBot) {
    return this.req({
      method: 'get',
      url: this.join(`bots/${botId}`),
    })
  }

  update({ botId, botInfo }: IUpdateBot) {
    return this.req({
      method: 'PATCH',
      url: this.join(`bots/${botId}`),
      data: botInfo,
    })
  }

  delete({ botId }: IDeleteBot) {
    return this.req({ method: 'delete', url: this.join(`bots/${botId}`) })
  }

  getChatRecords(props: IGetBotChatRecords) {
    return this.req({ method: 'get', url: this.join(`bots/${props.botId}/records`), data: props })
  }

  sendFeedback({ userFeedback }: ISendBotFeedback) {
    return this.req({ method: 'post', url: this.join(`bots/${userFeedback.botId}/feedback`), data: userFeedback })
  }

  getFeedback(props: IGetBotFeedback) {
    return this.req({ method: 'get', url: this.join(`bots/${props.botId}/feedback`), data: props })
  }

  async getRecommendQuestions(props: IGetBotRecommendQuestions) {
    const res = await this.req({
      method: 'post',
      url: this.join(`bots/${props.botId}/recommend-questions`),
      data: props,
      stream: true,
    })
    return new StreamResult(res)
  }

  async generateBot(props: IGenerateBot) {
    const res = await this.req({ method: 'post', url: this.join('generate-bot'), data: props, stream: true })
    return new StreamResult(res)
  }

  async getPreview(props: IBotPreview) {
    const res = await this.req({ method: 'post', url: this.join('preview'), data: props, stream: true })
    return new StreamResult(res)
  }

  generateImage(props: IGenerateImage) {
    return this.req({ method: 'post', url: this.join('generate-image'), data: props })
  }

  async sendMessage(props: IBotSendMessage) {
    const res = await this.req({
      method: 'post',
      url: this.join(`bots/${props.botId}/send-message`),
      data: props,
      stream: true,
    })
    return new StreamResult(res)
  }

  private join(url: string) {
    return `${this.baseUrl}/${url}`
  }
}

type BotEventStreamData = { content: string }

class StreamResult {
  private _eventSourceStream: ReadableStream<ParsedEvent>

  constructor(_stream: ReadableStream<Uint8Array>) {
    const stream = toPolyfillReadable(_stream) as typeof _stream
    this._eventSourceStream = stream
      .pipeThrough(new TextDecoderStream())
      .pipeThrough(createEventSourceParserTransformStream())
  }

  private get teeedStream() {
    const [s1, s2] = this._eventSourceStream.tee()
    this._eventSourceStream = s2
    return s1
  }

  get eventSourceStream() {
    return createAsyncIterable(this.teeedStream)
  }

  get dataStream() {
    return createAsyncIterable(this.eventSourceStream.pipeThrough(new TransformStream<ParsedEvent, BotEventStreamData>({
      transform(chunk, controller) {
        try {
          const data = JSON.parse(chunk.data)
          controller.enqueue(data)
        } catch (e) {
          if (chunk.data !== '[DONE]') {
            console.warn('Error when transforming event source data to json', e, chunk)
          } else {
            controller.terminate()
          }
        }
      },
    }),),)
  }

  get textStream() {
    return createAsyncIterable(this.dataStream.pipeThrough(new TransformStream<BotEventStreamData, string>({
      transform(chunk, controller) {
        controller.enqueue(chunk?.content ?? '')
      },
    }),),)
  }
}
