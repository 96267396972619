import { ZhiPuSimpleModel } from './ZhiPu/index'
import { HunYuanBetaSimpleModel } from './HunYuanBeta/index'
import { HunYuanSimpleModel } from './HunYuan/index'
import { ArkSimpleModel } from './Ark/index'
import { DSSimpleModel } from './DashScope/index'
import { YiSimpleModel } from './Yi/index'
import { MoonshotSimpleModel } from './Moonshot/index'
import { HunYuanExpSimpleModel } from './HunYuanExp/index'
import { HunYuanOpenSimpleModel } from './HunYuanOpen/index'
import { DeepSeekSimpleModel } from './DeepSeek'

export const MODELS = {
  hunyuan: HunYuanSimpleModel,
  'hunyuan-beta': HunYuanBetaSimpleModel,
  ark: ArkSimpleModel,
  dashscope: DSSimpleModel,
  '01-ai': YiSimpleModel,
  moonshot: MoonshotSimpleModel,
  zhipu: ZhiPuSimpleModel,
  'hunyuan-exp': HunYuanExpSimpleModel,
  'hunyuan-open': HunYuanOpenSimpleModel,
  deepseek: DeepSeekSimpleModel,
}

export {
  ZhiPuSimpleModel,
  HunYuanBetaSimpleModel,
  HunYuanSimpleModel,
  ArkSimpleModel,
  DSSimpleModel,
  YiSimpleModel,
  MoonshotSimpleModel,
  HunYuanExpSimpleModel,
  HunYuanOpenSimpleModel,
}

export { ReactModel, toolMap } from './model'
